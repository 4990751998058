a {
    text-decoration: none;
}

.backGround {
    background-color: #144d46;
}

.img {
    border-radius: 20px;
    height: 180px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-top: -24px;
}
h4{
    text-align: center;
}
.freeDemo{
    display: flex;
    align-items: center;
    justify-content: center;
}
h2 {
    position: relative;
    margin-bottom: 40px !important;
}


.fonts {
    font-size: 18px;
    margin-bottom: 30px;
}

.pragraph {
    font-size: 14px;
    color: #d9d9d9;
    line-height: 2.2;
}

.icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    text-decoration: none
}

.copyright {
    padding: 6px;
    background-color: #161616;
    text-align: center;
    color: #d9d9d9;
}

strong {
    color: #e5b14c;
    text-decoration: none;
}

.box {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    background-image: linear-gradient(to top, #e5b14c 50%, rgba(128, 128, 128, 0.26) 50%);
    background-position: top;
    background-size: 100% 200%;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    position: fixed;
    right: 28px;
    bottom: 90px;
    transition: all 0.5s;
}

.box:hover {
    background-position: bottom;
}

.box:hover a {
    color: white;
}

.box a {
    text-decoration: none;
    color: grey;
    font-size: 20px;
    margin-right: 5px;
}

.freeDemo a {
    text-transform: capitalize;
    text-decoration: none;
    background-image: linear-gradient(to right, #e5b14c 50%, #144d46 50%);
    background-position: right;
    background-size: 200% 100%;
    color: #d9d9d9;
    border: 1px solid #e5b14c;
    padding: 10px 30px;
    font-family: sans-serif;
    border-radius: 5px;
    transition: all 1s;
}

.freeDemo a:hover {
    color: white;
    background-position: left;
}

i {
    margin-left: 5px;
}

.footerBOX {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
@media only screen and (max-width: 768px) {
    .box{
        right: 25px;
    }
}