.registration-form {
  margin: 0 auto;
  width: 40%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}
.inputDir{
  direction:rtl;
}
.form-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  align-items: center;
}

.form-row label {
  font-weight: bold;
  text-align: end;
}
.form-row input[type="text"],
.form-row input[type="email"],
.form-row input[type="tel"],
.form-row select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.form-row select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg"><path d="M11.7 0.3c-0.4-0.4-1.1-0.4-1.5 0L6 4.6 1.8 0.3c-0.4-0.4-1.1-0.4-1.5 0 -0.4 0.4-0.4 1.1 0 1.5l5.2 5.2c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3l5.2-5.2C12.1 1.4 12.1 0.7 11.7 0.3z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px 8px;
}

.submit-btn {
  background-color: #223b33;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.submit-btn:hover {
  background-color: #223b33;
}

.submit-btn:active {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

@media only screen and (max-width: 768px) {
  .registration-form {
      width: 80%;
  }

  .form-row {
    display:flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .inputDir {
    text-align: center;
  }

  .media {
    width: 80%;
  }
.country,.whats,.gender,.whats_input,.how_find_us{
  width: 90% !important;
}
.parent_whats{
  display: flex;
  align-items: center;
  justify-content: center;
}
}