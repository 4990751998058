.tableDirction {
    direction: rtl;
    background-color: rgb(229, 237, 229);
}

.dirction {
    direction: rtl;
}

.parentTable {
    padding: 20px;
    overflow: auto;
    position: relative;
    direction: rtl;
}

.head {
    text-align: center;
    padding: 20px;
    background-color: #223b33;
}

th {
    padding: 10px !important;
    color: #d9d9d9 !important;
    font-size: small;
}

td {
    padding: 30px 15px !important;
    border: 1px solid #223b33 !important;
    text-align: center;
}

.tableIcon {
    color: #e5b14c;
}

.headerOfstudentTable {
    padding: 20px;
    direction: rtl;
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.img {
    border-radius: 20px;
    height: 100px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.navStudent {
    padding: 20px;
    direction: rtl;
    display: flex;
    align-items: start;
    justify-content: space-around;
    background-color: rgb(229, 237, 229);
    border-radius: 15px;
    font-size: 18px;
    margin-bottom: 20px;
}

.linkOfStudent:hover {
    color: #e5b14c !important;
}


.feedbackLink strong:hover {
    color: #000 !important;
}

.date {
    display: flex;
    align-items: flex-end;
    justify-content: end;
}

.add-teacher {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.orangeBackgorund{
    background-color: #e5b14c;
}

@media only screen and (max-width: 510px) {

    .logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .headerOfstudentTable {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .date {
            width: 100% !important;
            text-align: center !important;
            display: flex;
                align-items: center;
                justify-content: center;
        }
        
        .text {
            width: 100% !important  ;
            
            text-align: center !important;
        }

        .zoomLink {
            font-size: 13px;
        }
}

@media only screen and (max-width: 410px) {
    .marginBott {
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .navStudent {
            flex-wrap: wrap;
            text-align: center;
    }
          .zoomLink {
              font-size: 10px;
          }
}