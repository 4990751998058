
.parentPricing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 !important;}
.pricing-card {
    margin: 5px 0 !important;
    margin: 20px;
    height: 400px;
    background-color:#E5EDE5 !important;
            
        
    /*width: calc(30 - 40px);
    */transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
}

.pricing-card:hover {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
}

.pricing-header {
    color: white !important;
    text-align: center;
    padding: 20px;
}

.pricing-price {
    text-align: center;
    font-size: 50px;
    margin-bottom: 20px;
}

.currency {
    font-size: 30px;
    font-weight: bold;
    margin-right: 10px;
}

.price {
    font-weight: bold;
    margin-right: 5px;
}

.period {
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
}

.pricing-features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.pricing-features li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.check-icon {
    margin-right: 10px;
    color: green;
}

.buy-button {
    display: block;
    margin: 0 auto;
    background-color: #144d46 !important;
    color: white !important;
    width: 100%;
}

.buy-button:hover{
    background-color: orange !important;
}
@media only screen and (max-width: 768px) {
    h2.offers{
        margin: 0 !important;
    }
}