@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}
*{
  font-family: 'Poppins', sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.close{
  color: red;
  cursor: pointer;
  margin: auto;
  margin-bottom:20px ;
  background-color: white;
  width: fit-content;
  padding:2px 5px;
  border-radius: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Google translate  */
.skiptranslate {
  display: none !important;
  font-size: 0 !important;
}

.skiptranslate:first-child select {
  @apply bg-dark;
  color: white !important;
  border: 1px solid white;
  padding-right: 40px;
}
.skiptranslate:nth-child(2) {
  color: white;
}
.skiptranslate span {
  height: 0;
  display: none;
}
.skiptranslate iframe {
}
.goog-te-combo {
  height: 0;
  display: none;
}
.goog-te-combo:first-child {
  display: block;
  @apply h-[40px] px-[12px];
  font-weight: 700;
  font-size: 14px;
  border-radius: 10px;
}
.goog-te-gadget {
  display: block !important;
}
.slick-arrow {
  display: none !important;
}

.animation {
  animation: fade 0.5s ease-in-out;
  -webkit-animation: fade 0.5s ease-in-out;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rotatee0 {
  transform: translate(-50%, -50%) rotate(75deg);
  -webkit-transform: translate(-50%, -50%) rotate(75deg);
  -moz-transform: translate(-50%, -50%) rotate(75deg);
  -ms-transform: translate(-50%, -50%) rotate(75deg);
  -o-transform: translate(-50%, -50%) rotate(75deg);
}
.rotatee1 {
  transform: translate(-50%, -50%) rotate(130deg);
  -webkit-transform: translate(-50%, -50%) rotate(130deg);
  -moz-transform: translate(-50%, -50%) rotate(130deg);
  -ms-transform: translate(-50%, -50%) rotate(130deg);
  -o-transform: translate(-50%, -50%) rotate(130deg);
}
.rotatee2 {
  transform: translate(-50%, -50%) rotate(180deg);
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  -moz-transform: translate(-50%, -50%) rotate(180deg);
  -ms-transform: translate(-50%, -50%) rotate(180deg);
  -o-transform: translate(-50%, -50%) rotate(180deg);
}
.rotatee3 {
  transform: translate(-50%, -50%) rotate(230deg);
  -webkit-transform: translate(-50%, -50%) rotate(230deg);
  -moz-transform: translate(-50%, -50%) rotate(230deg);
  -ms-transform: translate(-50%, -50%) rotate(230deg);
  -o-transform: translate(-50%, -50%) rotate(230deg);
}
.rotatee4 {
  transform: translate(-50%, -50%) rotate(300deg);
  -webkit-transform: translate(-50%, -50%) rotate(300deg);
  -moz-transform: translate(-50%, -50%) rotate(300deg);
  -ms-transform: translate(-50%, -50%) rotate(300deg);
  -o-transform: translate(-50%, -50%) rotate(300deg);
}
.success .slick-slide:nth-child(odd) {
  @apply mt-12;
}
