
/* 
styling for all courses
*/
.QuranCourse {
    padding: 40px;
}

.QuranParent {
    padding: 50px;
    border: 1px solid burlywood;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.QuranText {
    direction: rtl;
    font-weight: bold;
    font-size: 20px;
}

.QuranImg {
    height: 300px;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 10px;
}

