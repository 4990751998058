.parentCard{
    direction: rtl;
    border: 1px solid #144d46;
    border-radius: 30px;
    background-color:rgb(229, 237, 229) ;
    width: fit-content;
    padding:5px

}

.imgCard{
    padding: 8px;
    height: 100px;
    width: 100px;
    border-radius:50%;
    border: 2px solid #144d46;
    background-color:white;
}