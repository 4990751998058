* {
    box-sizing: border-box;
}

.parant-7 {
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
}

/* .box-12:hover,
.box-12-1:hover {
    transform: scale(1.1);
} */

.box-12-1 {
    margin-bottom: 10px;
    font-family: sans-serif;
    text-transform: capitalize;
    border-radius: 10px;
    padding: 10px;
    transition: all 0.5s;
    direction: rtl;
    background-color: rgb(229, 237, 229);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.box-12 h2 {
    font-size: 14px;
}

.box-12 span {
    font-size: 13px;
}

.box-12 p {
    font-size: 13px;
    line-height: 1.5;
    color: #2f89fc;
}

.box-12 h1 {
    font-size: 20px;
}

.box-12 a {
    text-decoration: none;
    color: white;
}

.box-12 img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.box-12-1 h2 {
    font-size: 14px;
    color: black;
}

.box-12-1 span {
    font-size: 13px;
    color: gray;
    font-weight: bold;
}

.box-12-1 p {
    font-size: 13px;
    line-height: 1.5;
    color: gray;
}

.box-12-1 h1 {
    font-size: 20px;
    color: black;
    cursor: pointer;
}

.box-12-1 a {
    text-decoration: none;
    color: #2f89fc;
}

.box-12-1 img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.inner-box-12 {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.box-13 {
    color: white;
    font-family: sans-serif;
    text-transform: capitalize;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.box-13 h1:hover {
    text-decoration: underline;
}

.flex {
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 100%;

}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: url() !important;
}

.carousel-indicators {
    bottom: -28px !important;
}

.paragraph {
    word-spacing: 3px;
}

@media only screen and (max-width: 768px) {

    .box-12-1 {
        width: 100%;
    }
}