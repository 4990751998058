.wrapper {
    padding: 25px 0;
    background-color: #f7f7f7;
}

h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
}

.courseCards {
    margin-top: 50px;
}

.card {
    position: relative;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    -moz-transition: box-shadow 0.3s ease-in-out;
    -ms-transition: box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.cardImgWrapper {
    position: relative;
}

.cardOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.card:hover .cardOverlay {
    opacity: 1;
}

.cardContent {
    padding: 20px;
}

h5 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 20px;
}

.btn-secondary {
    font-size: 16px;
    border-radius: 0;
    padding: 12px 20px;
    background-color: #223b33;
    border-color: #223b33;
}

.btn-secondary:hover {
    background-color: #fff;
    color: #223b33;
    border-color: #223b33;
}
