.navAr {
  direction: rtl;
  position: sticky;
  top: 0;
  z-index: 10;
  left: 0;
}

.img {
  height: 60px;
  width: 60px;
  /* border-radius: 10px; */
}

.nav {
  background-color: #144d46 !important;
  font-size: 20px;
}

.links {
  color: rgba(255, 255, 255, 0.84) !important;
  color: #fff !important;
  text-decoration: none !important;
}

nav a {
  color: #fff !important;
  text-decoration: none;
}

nav a:hover {
  background-color: orange !important;
}

Link {
  text-decoration: none;
}

.colaps {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.childNav {
  width: 100%;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center;
}

.innerNav {
  width: 50%;
  display: flex !important;
  justify-content: space-around !important;
}
@media only screen and (max-width: 768px) {
  .img {
    width: 45px;
    height: 45px;
  }
}
