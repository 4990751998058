.aboutUs{
    direction: rtl;
}
.aboutImg{
    height: 100%;
    width: 100%;
        border-radius: 50px;
}
.imgBox{
    height: 400px;
   
}
.aboutUsFelex{
    align-items: center;
}