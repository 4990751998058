
.relative{

}
select{
    padding: 0 !important;
    /* display: flex !important; */
    gap: 15px !important;
 justify-content: center !important;
    font-size: 13px !important;
}
option{
    color: black;
}
.two{
    padding: 0 !important;
    font-size: 15px !important;
    max-width: fit-content;
    cursor: pointer;
}