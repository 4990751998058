/* p,img,h2, .testimonial-card:hover {
    box-shadow: 2px 20px 40px rgba(226, 16, 16, 0.15);
    transition: all 0.3s ease;
    } */

.testimonial-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

h2::after {
    content: "";
    position: absolute;
    top: 45px;
    width: 10%;
    height: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: #e5b14c;
    left: 45%;
}
.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}