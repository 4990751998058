/* Chat container */
.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Messages container */
.messages-container {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 10px;
    height: 500px;
    scroll-behavior: auto;
}

/* Individual message */
.message {
    margin-bottom: 10px;
}

.message.user {
    direction: ltr;
    justify-content: right;
    margin-bottom: 20px;
}

.message.other {
    direction: rtl;
    margin-bottom: 20px;
}

.message-sender.user {
    font-weight: bold;
    color: #144D46;
    margin-right: 5px;
}
.message-sender.other {
    font-weight: bold;
    color: #0f0357;
    margin-right: 5px;
    
}
.chat-icon {
    background-color: transparent;
}
.chat-icon:hover {
    background-color: #f2f2f2;
}
.message-content.user {
    background-color: #144D46;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}
.message-content.other {
    background-color: #0f0357;
    color: white;
    padding: 5px 10px;
        border-radius: 10px;

}

.message-date.other {
    margin-top: 10px;
    direction: ltr;
    text-align: end;
    color: #b9481b;
    font-size: 13px;
}
.message-date.user {
    color: #b9481b;
    font-size: 13px;
    margin-top: 10px;

}

/* Input container */
.chat-input-container {
    align-items: center;
    padding: 10px;
}

.chat-input{
    flex-grow: 1;
    padding: 5px;
    width: 90%;
    margin-left: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 5px;
    direction: rtl;
}

button {
    padding: 5px 20px;
    border-radius: 20px;
    border: none;
    background-color: #144D46;
    color: #fff;
    cursor: pointer;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

button:hover {
    background-color: #025906;
}